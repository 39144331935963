<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="data" :row-key="_id" :loading="tableLoading">
      <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="custom-filter-dropdown">
          <a-input
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm)"
            >Search</a-button
          >
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
            >Reset</a-button
          >
        </div>
      </template>
      <template #filterIcon="{ text: filtered }">
        <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #createdAt="{ text }">
        {{ moment(text).format('DD-MM-YYYY') }}
      </template>
      <template #action="{ record }">
        <span>
          <a-button @click="suppMessage(record)" class="btn btn-sm btn-light">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Supprimer
          </a-button>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
/* eslint-disable */
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue'
export default {
  created() {
    this.tableLoading = true
    ApiClient.post('/inscriptions/filter', {
      query: {},
    })
      .then((res) => {
        this.data = res.data
      })
      .finally(() => {
        this.tableLoading = false
      })
  },
  data() {
    return {
      tableLoading: false,
      columns: [
        {
          title: 'Nom',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'name',
          },
          sorter: (a, b) => a.name - b.name,
          onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'Prenom',
          dataIndex: 'lastName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'lastName',
          },
          sorter: (a, b) => a.lastName - b.lastName,
          onFilter: (value, record) => record.lastName.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'email',
          },
          sorter: (a, b) => a.email - b.email,
          onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'Téléphone',
          dataIndex: 'phone',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'phone',
          },
          sorter: (a, b) => a.phone - b.phone,
          onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'Message',
          dataIndex: 'message',
          width: '50%',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'government',
          },
          sorter: (a, b) => a.message - b.message,
          onFilter: (value, record) => record.message.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
        },
      ],
      searchText: '',
      searchInput: null,
      data: [],
    }
  },
  methods: {
    handleReset() {
      clearFilters(clearFilters)
      searchText.value = ''
    },
    handleSearch(selectedKeys, confirm) {
      confirm()
      searchText.value = selectedKeys[0]
    },
    suppMessage(record) {
      const updateData = { status: 'inactive' }
      ApiClient.delete('/inscriptions/' + record._id)
        .then(() => {
          this.data = this.data.filter(function (obj) {
            return obj._id !== record._id
          })
          message.success(`Message supprimé`)
        })
        .catch((e) => {
          message.warning('Impossible de supprimer le message')
        })
    },
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
